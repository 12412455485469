.ViewSubtitle {
  margin-top: -0.35em;
}

@media print {
  .AppView {
    font-size: 82%;
  }

  .AppHeader,
  .ViewTitle,
  .ViewSubtitle {
    display: none;
  }
}

.Experiences-filters-controls {
  margin: 2em;
}

@media print {

  .Experiences-filters-controls {
    display: none;
  }
}

  .Experiences-filters-controls > .toggle-filters-view,
  .Experiences-filters-controls > .clear-filters,
  .Experiences-filters-controls > .download-pdf {
    display:  inline-block;
    cursor: pointer;
    margin-right: 1em;
    margin-bottom: .5em;
    padding: .25em .75em;
    border-radius: .75em;
    border: 1px solid #aaa;
    color: #aaa;
  }

  .Experiences-filters-controls > .download-pdf {
    transition-property: color, border-color;
    transition-duration: .15s;
    transition-timing-function: ease-in-out;
  }

  .Experiences-filters-controls > .download-pdf.is-downloading {
    border-color: orange;
    color: orange;
    cursor: default;
  }

.Experiences-filters {
  margin: 2em;
}

  .Experiences-filters > .Experiences-filters-title {
    color: #333;
  }

  .Experiences-filters > .Experiences-filter {
    margin: 1.5em 0;
  }

    .Experiences-filters > .Experiences-filter > .Experiences-filter-title {
      font-size: .83em;
      color: #333;
    }

    .Experiences-filters > .Experiences-filter > .Experiences-filter-option {
      display: inline-block;
      margin-right: .5em;
      border: 1px solid #aaa;
      font-size: .7em;
      text-transform: uppercase;
      padding: .1em .64em;
      border-radius: .64em;
      color: #aaa;
      cursor: pointer;
    }

    .Experiences-filters > .Experiences-filter.Experiences-filter-technology > .Experiences-filter-option {
      text-transform: none;
    }

    .Experiences-filters > .Experiences-filter > .Experiences-filter-option.Experiences-filter-option-active {
      border-color: limegreen;
      color: limegreen;
    }

.Experiences-filter-results {
  margin: 2em;
  padding: .5em 2em;
  border: 1px dashed #ccc;
  border-radius: 1em;
}

  .Experiences-filter-results > span {
    color: #ccc;
    font-size: .85em;
  }

.Experiences-tally {
  margin: 2em;
  font-weight: 900;
  line-height: 1.1em;
  letter-spacing: -1px;
}

  .Experiences-tally > .Experiences-tally-filtered,
  .Experiences-tally > .Experiences-tally-unfiltered {
    font-size: 125%;
  }

  .Experiences-tally > .Experiences-tally-filtered {
    color: limegreen;
    margin-top: 1em;
  }

  .Experiences-tally > .Experiences-tally-filtered-caveat {
    display: none;
    font-weight: 400;
    color: #888;
  }

  @media print {
    .Experiences-tally > .Experiences-tally-filtered-caveat {
      display: block;
    }
  }

.Experiences {
  margin: 2em;
}

  .Experiences > .Experience {
    margin-bottom: 1.5em;
  }

  @media print {

    .Experiences > .Experience {
      page-break-inside: avoid;
    }
  }

    .Experiences > .Experience > .Experience-header {
      overflow: hidden;
    }

      .Experiences > .Experience > .Experience-header > .Experience-title {
        color: #000;
        font-weight: 900;
        font-size: 1.2em;
        letter-spacing: -1px;
        float: left;
      }

      .Experiences > .Experience > .Experience-header > .Experience-type {
        float: right;
        color:  #fff;
        font-size: .7em;
        margin-top:  0.5em;
        line-height: 1.25em;
        padding: .25em .75em;
        border-radius: .75em;
        text-transform: uppercase;
      }

        .Experiences > .Experience.Experience-type-employment > .Experience-header > .Experience-type {
          background-color: #c130a2;
        }

        .Experiences > .Experience.Experience-type-contract > .Experience-header > .Experience-type {
          background-color: purple;
        }

        .Experiences > .Experience.Experience-type-entrepreneur > .Experience-header > .Experience-type {
          background-color: darkorchid;
        }

        .Experiences > .Experience.Experience-type-open-source > .Experience-header > .Experience-type {
          background-color: mediumaquamarine;
        }

        .Experiences > .Experience.Experience-type-volunteer > .Experience-header > .Experience-type {
          background-color: darkorange;
        }

    .Experiences > .Experience > .Experience-when {
      position: relative;
      color: #888;
      font-size: 1.1em;
      margin-top: -0.35em;
      letter-spacing: -1px;
    }

    .Experiences > .Experience > .Experience-technologies {
      margin: .85em;
    }

      .Experiences > .Experience > .Experience-technologies .Experience-technology {
        display:  inline-block;
        margin-right: .5em;
        border: 1px solid #888;
        padding: .1em .5em;
        font-size: .7em;
        line-height: 1.2em;
        color: #888;
        border-radius: .5em;
        cursor: default;
      }

      .Experiences > .Experience > .Experience-technologies .Experience-technology:hover {
        background-color: palegoldenrod;
      }

      .Experiences > .Experience > .Experience-technologies.Experience-technologies-filtered .Experience-technology {
        color: #ccc;
        border-color: #ccc;
      }

      .Experiences > .Experience > .Experience-technologies.Experience-technologies-filtered .Experience-technology.Experience-technologies-filter-match {
        color: #888;
        border-color: #888;
      }

        .Experiences > .Experience > .Experience-technologies .Experience-technology > .Experience-technology-type {
          display: none;
          position: absolute;
          border-radius: .5em;
          margin-top: -3.55em;
          margin-left: -1.75em;
          padding: .35em .55em;
          background-color: palegoldenrod;
          color: #9a9;
          text-align: center;
        }

        .Experiences > .Experience > .Experience-technologies .Experience-technology:hover > .Experience-technology-type {
          display: block;
        }

    .Experiences > .Experience > .Experience-nutshell {
      margin: 1em;
      color: #555;
      font-weight: 900;
      font-size: .85em;
    }

    .Experiences > .Experience > .Experience-notes {
      margin:  1em 1em 0 1em;
      transition: max-height .375s cubic-bezier(0, 1, 0, 1);
      overflow: hidden;
      max-height: 9999px;
    }

    .Experiences > .Experience > .Experience-notes.Experience-notes-hidden {
      max-height: 0;
      transition: max-height .1s cubic-bezier(1, 0, 1, 0);
    }

      .Experiences > .Experience > .Experience-notes > .Experience-note {
        font-size: .85em;
        margin-bottom: 1em;
        color: #777;
      }

        .Experiences > .Experience.Experience-type-employment > .Experience-notes > .Experience-note a {
          color: #c130a2;
        }

        .Experiences > .Experience.Experience-type-contract > .Experience-notes > .Experience-note a {
          color: purple;
        }

        .Experiences > .Experience.Experience-type-entrepreneur > .Experience-notes > .Experience-note a {
          color: darkorchid;
        }

        .Experiences > .Experience.Experience-type-open-source > .Experience-notes > .Experience-note a {
          color: mediumaquamarine;
        }

        .Experiences > .Experience.Experience-type-volunteer > .Experience-notes > .Experience-note a {
          color: darkorange;
        }

        .Experiences > .Experience > .Experience-notes > .Experience-note a.inline-block {
          display: inline-block;
        }

        .Experiences > .Experience > .Experience-notes > .Experience-note code {
          background-color: #ddd;
          border: 1px solid #aaa;
          color: darkgoldenrod;
          font-size: .75em;
          padding: 0em .45em;
          border-radius: .5em;
          margin: 0 0.1em;
          vertical-align: .2em;
        }

    .Experiences > .Experience > .Experience-notes-view-toggle {
      margin: 0 .85em;
    }

    @media print {

      .Experiences > .Experience > .Experience-notes-view-toggle {
        display: none;
      }
    }

      .Experiences > .Experience > .Experience-notes-view-toggle > span {
        text-transform: uppercase;
        font-size: 0.65em;
        cursor: pointer;
        color: #aaa;
      }

.ViewSubtitle {
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: -1px;
  margin-top: -0.5em;
  line-height: .85em;
  color: #aaa;
}

  .ViewSubtitle > .emphasis {
    color: #555;
  }
